import CustomContent from "@arcgis/core/popup/content/CustomContent.js";
import appConfig from "../../config/AppConfig";

interface LandaxDto {
    name: string;
    surveyUrl: string;
}

const contentLandax = new CustomContent({
    outFields: ["a99_rds"],
    creator: async (event: any) => {
        const attributes = event.graphic.attributes;
        const landaxId = attributes["a99_rds"] || attributes["A99_RDS"];
        const result = await fetchLandaxData(landaxId);
        return render(result);
    }
});

const render = (result: LandaxDto[] | string) => {
    if (!Array.isArray(result))
        return `<div>
            <div><b>Landax sjekklister:</b></div>
            <div>${result}</div>
            </div>`;

    return `<div>
            <div><b>Landax sjekklister:</b></div>
            <div>${result.map(survey => "<a href='" + survey.surveyUrl + "' target='_blank'>" + survey.name + "</a>").join("<br />")}</div>
            </div>`;
}

const fetchLandaxData = async (landaxId: string) => {
    if (landaxId == null || landaxId == "" || landaxId == "-") {
        return "Ingen Landax ID for å hente Landax sjekkliste";
    }

    if (landaxId.startsWith("<") && landaxId.endsWith(">")) {
        return (`Invalid Landax ID, ${encodeURIComponent(landaxId)}`);
    }

    const url = appConfig.app.apiUrl + "/v1/landax/survey/" + landaxId;
    let landaxData: LandaxDto[];
    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error(`Response status: ${response.status}`);
            return "Feil ved henting av Landax sjekkliste";
        }

        landaxData = await response.json();
        if (landaxData.length === 0) {
            console.debug("Ingen sjekkliste funnet i Landax for <i>'a99_rds'=" + landaxId + "</i>");
            return "Ingen sjekkliste funnet i Landax";
        }
        return landaxData;
    } catch (error) {
        console.error(error);
        return "Feil ved henting av Landax sjekkliste";
    }
}

export default contentLandax;