import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import ArcGISViewContext from "../../../../context/ArcGISViewContext";
import {
  useCreateLoadAGSLayers,
  useHookLayer,
} from "../../../../hooks/useCreateLoadAGSLayers";
import {
  getAllLayerIds,
  loadProjectLayers,
} from "../../../../store/projectPlanningSlice";
import { StoreState } from "../../../../store/rootReducer";
import Submenu from "../Submenu";
import SubmenuTitle from "../SubmenuTitle";
import ProjectPlanningDataLoading from "./ProjectPlanningDataLoading";
import { LandaxTemplate } from "../../../../map/PopupTemplate";
import { loadProjectMetaData } from "../../../../map/MapServiceFactoryUtils";
import CenterLineToggle from "./CenterLineToggle";
import { Divider } from "@mui/material";
import { FieldsInfoName2DLayer } from "../../../../map/popupCustomContent/OutputFieldsScenes";

const ProjectPlanning = () => {
  const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false);
  const projectPlanningMetadata = useSelector(
    (state: StoreState) => state.projectPlanning.data
  );
  const dispatch = useDispatch();
  const { activeView } = useContext(ArcGISViewContext);
  const [loadLayers, setLoadLayers] = useState<useHookLayer[]>([]);
  const { loadedLayers, loading, error } = useCreateLoadAGSLayers(
    loadLayers,
    activeView.type,
    true,
    true
  );

  // on init, only once, load metadata for project planning layers and set in store
  useEffect(() => {
    setLoadingMetadata(true);

    // Prevent reloading data and erasing current state every time the menu is rendered
    if (Object.keys(projectPlanningMetadata).length > 0) {
      setLoadingMetadata(false);
      return;
    }

    const loadMetaData = async () => {
      let attributesArray = await loadProjectMetaData();
      dispatch(loadProjectLayers({ attributesArray }));
      setLoadingMetadata(false);
    };

    loadMetaData();
  }, []);

  // create and load layers when metadata is loaded. Add to View
  useEffect(() => {
    if (Object.keys(projectPlanningMetadata).length === 0) return;

    const layerData = Array.from(
      getAllLayerIds(projectPlanningMetadata, activeView?.type)
    );

    const layers = layerData.map((dataPiece) => {
      const [layerId, layerType] = dataPiece.split("|");
      let props = {
        id: layerId,
        visible: false,
        portalItem: {
          id: layerId,
        },
      };

      // TODO: Add outfields to 3D layers???
      // if (activeView.type === "2d") {
        (props as __esri.FeatureLayer).outFields = FieldsInfoName2DLayer;
      // }

      // add template to both 2D and 3D layers
      (props as __esri.FeatureLayer).popupTemplate = LandaxTemplate(activeView);

      return {
        type: layerType,
        layerprops: props,
      } as useHookLayer;
    });

    setLoadLayers(layers);
  }, [projectPlanningMetadata, activeView.type]);

  return (
    <Submenu className="submenu-layers">
      <SubmenuTitle text={I18n.t("sidebar.projectPlanning.title")} />
      <div className="sidebar-submenu-content">
        <ProjectPlanningDataLoading
          loadingMetadata={loadingMetadata}
          loadingServices={loading || loadedLayers.length === 0}
          error={error as unknown as boolean}
          projectPlanningMetadata={projectPlanningMetadata}
        />
        <Divider sx={{ margin: 1 }} />
        {activeView.type === "2d" ? <CenterLineToggle /> : <></>}
      </div>
    </Submenu>
  );
};

export default ProjectPlanning;
