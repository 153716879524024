import PopupTemplate from "@arcgis/core/PopupTemplate";
import contentLandax from "./popupCustomContent/LandaxContent";
import { FieldsInfo2DLayer } from "./popupCustomContent/OutputFieldsScenes";
import contentFieldsWithValue from "./popupCustomContent/FieldsWithValueContent";

// Create a custom popup template
export const LandaxTemplate = (view: __esri.View): __esri.PopupTemplate => {
  return new PopupTemplate({
    overwriteActions: true,
    outFields: FieldsInfo2DLayer.map((field) => field.fieldName),
    title: "{a01_prosjektnavn}",
    content: [
      contentLandax,
      contentFieldsWithValue
    ]
  });
};