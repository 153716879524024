// arcgis
import Basemap from "@arcgis/core/Basemap";
import Layer from "@arcgis/core/layers/Layer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import TileLayer from "@arcgis/core/layers/TileLayer";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import WMSLayer from "@arcgis/core/layers/WMSLayer";
import WMTSLayer from "@arcgis/core/layers/WMTSLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
import { ConfigMapLayer, ServiceType } from "../config/AppConfig";
import SceneLayer from "@arcgis/core/layers/SceneLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import ElevationLayer from "@arcgis/core/layers/ElevationLayer";

/// Create new layers from config
export default class MapServiceFactory {
  static _handles: __esri.WatchHandle[] = [];

  public static create(service: ConfigMapLayer): __esri.Layer {
    const serviceObject = this._createLayer(service);
    if (service.onLoadCallback) {
      this._handles.push(
        reactiveUtils.when(
          () => serviceObject.loaded,
          () => {
            (service.onLoadCallback as Function)(serviceObject);
          }
        )
      );
    }
    this._addErrorHandling(serviceObject);
    return serviceObject;
  }

  public static createBasemap(
    baseservices: Array<ConfigMapLayer>
  ): __esri.Basemap {
    const baseLayers = [];
    const baseServices = baseservices || [];
    for (const service of baseServices) {
      const layer = this._createLayer(service);
      this._addErrorHandling(layer);
      baseLayers.push(layer);
    }

    const basemap: __esri.Basemap = new Basemap({
      baseLayers: baseLayers,
    });

    return basemap;
  }

  public static loadMetadataTable(tableLayer: __esri.FeatureLayer) {
    return tableLayer.queryFeatures({ where: "1=1", outFields: ["*"] });
  }

  public static dispose(): void {
    this._handles.forEach((h) => h.remove());
  }

  /*** internals ***/
  private static _createLayer(service: ConfigMapLayer): __esri.Layer {
    let layer;
    switch (service.type) {
      case ServiceType.MapImageLayer:
        layer = new MapImageLayer(
          service.properties as __esri.MapImageLayerProperties
        );
        break;
      case ServiceType.FeatureLayer:
        let propsFeature = service.properties as __esri.FeatureLayerProperties;
        propsFeature.outFields = propsFeature.outFields ?? ["*"]; // set default to all fields
        layer = new FeatureLayer(propsFeature);
        break;
      case ServiceType.TileLayer:
        layer = new TileLayer(service.properties);
        break;
      case ServiceType.VectorTileLayer:
        layer = new VectorTileLayer(service.properties);
        break;
      case ServiceType.WMSLayer:
        layer = new WMSLayer(service.properties as __esri.WMSLayer);
        break;
      case ServiceType.WMSTLayer:
        layer = new WMTSLayer(service.properties as __esri.WMTSLayerProperties);
        break;
      case ServiceType.PortalItem:
        layer = Layer.fromPortalItem({
          portalItem: service.properties as __esri.PortalItem,
        });
        break;
      case ServiceType.GraphicsLayer:
        layer = new GraphicsLayer(service.properties);
        break;
      case ServiceType.ImageryLayer:
        layer = new ImageryLayer(
          service.properties as __esri.ImageryLayerProperties
        );
        break;
      case ServiceType.Scene: {
        let propsScene = service.properties as __esri.SceneLayerProperties;
        propsScene.outFields = propsScene.outFields ?? ["*"]; // set default to all fields
        layer = new SceneLayer(propsScene);
        break;
      }
      case ServiceType.ElevationLayer:
        layer = new ElevationLayer(
          service.properties as __esri.ElevationLayerProperties
        );
        break;
      default:
        console.warn(`Could not create new layer of type ${service.type}`);
        break;
    }

    return layer as __esri.Layer;
  }

  private static _addErrorHandling(layer: __esri.Layer): void {
    this._handles.push(
      reactiveUtils.when(
        () => layer.loadError,
        (error) => {
          console.warn(`Layer (id: ${layer.id}) error: ${error?.message}.`);
        }
      ),
      reactiveUtils.when(
        () => layer.loadWarnings,
        (warnings) => {
          console.warn(`Layer warning for ${layer.id}: ${warnings}`);
        }
      ),
      reactiveUtils.when(
        () => layer.loadStatus,
        (status) => {
          console.debug(`Layer status for ${layer.id}: ${status}`);
        }
      )
    );
    layer.on("layerview-create", (event) => {
      console.debug("LayerView created for layer with the id: ", layer.id);
    });
    layer.on("layerview-create-error", (event) => {
      console.error(
        "LayerView failed to create for layer with the id: ",
        layer.id
      );
      console.error("Error: ", event.error);
    });
    layer.on("layerview-destroy", (event) => {
      console.debug("LayerView destroyed for layer with the id: ", layer.id);
    });
  }
}
